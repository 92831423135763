.overview-div {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.hours-div {
  font-size: 2rem;
  color: white;
  background-color: black;
  text-align: center;
  width: 90%;
}

.flightmap-div {
  background-image: url("../flightmap.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100%;
  height: 2000px;
  width: 90%;
  margin-top: 10px;
  image-rendering: -webkit-optimize-contrast;
}
