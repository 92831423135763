.main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin: 10% 10% 10% 10%;
}

.content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  justify-content: space-evenly;
  background-image: url("../sky.jpg");
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 100% 100%;
  height: max-content;
  width: 95%;
  border: 1px solid black;
}

.name-div {
  color: white;
  font-size: 15vw;
  font-family: "Caudex", serif;
  text-shadow: 3px 3px 3px black;
  text-align: center;
}

.location-div,
.number-div,
.extra-div {
  font-family: "Caudex", serif;
  font-size: 6vw;
  color: white;
  text-shadow: 3px 3px 3px black;
  text-align: center;
}

.email-div {
  color: blue;
  font-family: "Caudex", serif;
  text-shadow: 1px 1px 1px white;
  font-size: 6vw;
  justify-items: center;
}

.button-div {
  margin-top: 10px;
  background-color: orange;
  font-size: 6vw;
  color: black;
  width: 95%;
  height: fit-content;
}
